import React from 'react'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ResetPassword from './ResetPassword'
import ShareDialog from './ShareDialog'
import ProjectsDialog from './ProjectsDialog'

const SettingsMenu = () => {
	const [open, setOpen] = React.useState(false)
	const [openShare, setOpenShare] = React.useState(false)
	const [openProjects, setOpenProjects] = React.useState(false)
	const anchorRef = React.useRef(null)
	const handleToggle = () => {
		setOpen(!open)
	}

	const handleClose = (event) => {
		setOpen(false)
	}

	return (
		<>
			<IconButton
				id="settings-button"
				size="large"
				ref={anchorRef}
				aria-label="Settings"
				aria-controls={open ? 'settings-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				color="inherit"
				onClick={handleToggle}>
				<SettingsIcon />
			</IconButton>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				placement="bottom-start"
				transition
				disablePortal>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: 'right top',
						}}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id="settings-menu"
									aria-labelledby="settings-button">
									<MenuItem onClick={() => { setOpenShare(true); handleClose() }}>Share this project</MenuItem>
									<MenuItem onClick={() => { setOpenProjects(true); handleClose() }}>Manage the projects</MenuItem>
									<ResetPassword/>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
			<ShareDialog open={openShare} handleClose={() => setOpenShare(false)} />
			<ProjectsDialog open={openProjects} handleClose={() => setOpenProjects(false)} />
		</>
	)
}

export default SettingsMenu