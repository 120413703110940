import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import DoneIcon from '@mui/icons-material/Done'
import { setFilter } from '../../actions/specifications'

const styles = {
	cell: { textAlign: 'center', verticalAlign: 'middle' }
}

const attributes = [
	{ id: 'later', title: 'To do later' },
	{ id: 'folderChecked', title: 'The folder is correct' },
	{ id: 'specificationChecked', title: 'The specification was checked' },
	{ id: 'testCreated', title: 'The test was created' },
	{ id: 'testLinked', title: 'The specification is linked to a test' },
	{ id: 'testWritten', title: 'The tests are written' },
	{ id: 'done', title: 'All tasks related to the specification are done' }]

const getValue = (value) => {
	return (value ? true : false)
}

const getKey = (specification) => {
	return attributes.map(o => getValue(specification[o.id]).toString()).join('_')
}

const calculate = (specifications) => {
	if (!specifications)
		return []
	const ret = {}
	specifications.forEach(o => {
		const key = getKey(o)
		if (!ret[key]) {
			ret[key] = { key }
			ret[key].count = 0
			attributes.forEach(attrib => ret[key][attrib.id] = getValue(o[attrib.id]))
		}
		++ret[key].count
	})
	const rows = Object.keys(ret).map(o => ret[o])
	rows.sort((a, b) => a.key < b.key ? -1 : a.key > b.key ? 1 : 0)
	return rows
}

const DashboardDialog = ({ open, handleClose }) => {
	const dispatch = useDispatch()
	const filter = useSelector(state => state.specifications.filter ? state.specifications.filter : {})
	const specifications = useSelector(state => state.specifications.all)
	const calculated = useMemo(() => calculate(specifications), [specifications])
	const view = (row) => () => {
		const filterToUpdate = { ...filter }
		for (let o in filterToUpdate)
			if (o.startsWith('filter'))
				filterToUpdate[o] = false
		attributes.forEach(o => {
			filterToUpdate[o.id] = row[o.id]
			filterToUpdate[`filter${o.id.charAt(0).toUpperCase() + o.id.slice(1)}`] = true
		})
		dispatch(setFilter(filterToUpdate))
		handleClose()
	}
	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
			disableEscapeKeyDown fullWidth
			maxWidth="md"><DialogTitle id="form-dialog-title">Dashboard</DialogTitle>
			<DialogContent>
				<table>
					<thead>
						<tr>
							{attributes.map(o => <th key={`col-${o.id}`}><Typography variant='body2'>{o.title}</Typography></th>)}
							<th>
								<Typography variant='body2'>Total</Typography>
							</th>
						</tr>
					</thead>
					<tbody>
						{calculated.map(row => <tr key={row.key}>
							{attributes.map(col => <td key={`cell-${row.key}-${col.id}`} style={styles.cell}>
								{row[col.id] && <DoneIcon color='success' />}
							</td>)}
							<th style={styles.cell}><Button onClick={view(row)}><Typography variant='body2'>
								{row.count}
							</Typography></Button></th>
						</tr>)}
					</tbody>
				</table>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Close
				</Button>
			</DialogActions>
		</Dialog>)
}

DashboardDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
}

export default DashboardDialog