import React, { useState } from 'react'
import { connect } from 'react-redux'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ClearIcon from '@mui/icons-material/Clear'
import DoneIcon from '@mui/icons-material/Done'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import SpecificationDialog from './SpecificationDialog'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import { handleDelete, setCurrent } from '../../actions/specifications'
import { parseSpecification } from '../../utils/parser'

const SpecificationItem = ({ specification, dispatch, current }) => {
	const [open, setOpen] = useState(false)
	const [internalSpecification, setInternalSpecification] = useState(specification)
	const setAttribute = (field, value) => {
		setInternalSpecification({ ...internalSpecification, [field]: value })
	}
	const openDialog = () => {
		setOpen(true)
		setInternalSpecification({ ...internalSpecification })
	}
	const deleteSpecification = () => {
		if (window.confirm("Please confirm the deletion"))
			dispatch(handleDelete(specification))
	}
	const setCurrentSpec = () => {
		if (current === specification.id)
			dispatch(setCurrent(null))
		else
			dispatch(setCurrent(specification))
	}
	return (<><ListItem button onClick={setCurrentSpec} selected={current === specification.id}  alignItems="flex-start">
		<ListItemAvatar>
			{specification.status === 'added' ? <IconButton edge="end" aria-label="edit">
				<NewReleasesIcon color='warning' fontSize='large' /> </IconButton> : null}
			{specification.status === 'deleted' ? <IconButton edge="end" aria-label="edit">
				<ClearIcon color='error' fontSize='large' /> </IconButton> : null}
		</ListItemAvatar>
		<ListItemText primary={specification.folder} secondary={parseSpecification(specification.specification)} sx={{ paddingRight: 7 }} />
		<ListItemSecondaryAction>

			<IconButton edge="end" aria-label="edit">
				{specification.done ?
					<DoneIcon color='success' /> :
					<QuestionMarkIcon color='warning' />}
			</IconButton>
			<IconButton edge="end" aria-label="edit" onClick={openDialog}>
				<EditIcon fontSize='small' />
			</IconButton>
			<IconButton edge="end" aria-label="delete" onClick={deleteSpecification}>
				<DeleteIcon fontSize='small' />
			</IconButton>
		</ListItemSecondaryAction>
	</ListItem>
		<SpecificationDialog open={open} handleClose={() => setOpen(false)}
			specification={internalSpecification}
			setAttribute={setAttribute} />
	</>)
}

const mapStateToProps = ({ specifications }, ownProps) => {
	return {
		current: specifications.current,
		...ownProps
	}
}

export default connect(mapStateToProps)(SpecificationItem)