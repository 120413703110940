import Main from './components/Main'
import SignIn from './components/SignIn'
import EmailToVerify from './components/EmailToVerify'
import { connect } from 'react-redux'


const App = ({ user }) => {
  return ( <>
      { user && user.emailVerified && <Main/> }
      { user && !user.emailVerified && <EmailToVerify/> }
      { !user && <SignIn/> }
    </>)
}

const mapStateToProps = ({ auth }, ownProps) => {
  return {
    ...ownProps,
    user: auth.user
  }
}

export default connect(mapStateToProps)(App)
