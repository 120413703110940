import {
	doc, updateDoc, collection, addDoc, getDoc, deleteDoc, setDoc
} from 'firebase/firestore'
import { db } from '../utils/firebase'

import { handleLoad as handleLoadSpec, unload as unloadSpec } from './specifications'
import { handleLoad as handleLoadTestcases, unload as unloadTestcases } from './testcases'

export const PROJECTS_SET_CURRENT = 'PROJECTS/SET_CURRENT'
export const PROJECTS_ADD = 'PROJECTS/ADD'
export const PROJECTS_REMOVE = 'PROJECTS/REMOVE'
export const PROJECTS_MODIFY = 'PROJECTS/MODIFY'
export const PROJECTS_UNLOAD = 'PROJECTS/UNLOAD'


const setCurrent = (current) => {
	return {
		type: PROJECTS_SET_CURRENT,
		current
	}
}

export const addProject = (project) => {
	return {
		type: PROJECTS_ADD,
		project
	}
}

export const removeProject = (project) => {
	return {
		type: PROJECTS_REMOVE,
		project
	}
}

export const modifyProject = (project) => {
	return {
		type: PROJECTS_MODIFY,
		project
	}
}

const clearProjects = () => {
	return {
		type: PROJECTS_UNLOAD
	}
}

export const loadProject = (projectRef, unsubscribers) => async (dispatch, getState) => {
	const { uid, projectId } = projectRef
	const docRef = doc(db, 'users', uid, 'projects', projectId)
	const projectDoc = await getDoc(docRef)
	const project = projectDoc.data()

	dispatch(setCurrent({...projectRef, ...project}))
	dispatch(handleLoadSpec(uid, projectId, unsubscribers))
	dispatch(handleLoadTestcases(uid, projectId, unsubscribers))
}


export const unloadProject = () => (dispatch, getState) => {
	dispatch(setCurrent(null))
	dispatch(unloadSpec())
	dispatch(unloadTestcases())
	dispatch(clearProjects())
}

export const handleSave = (project) => async (dispatch, getState) => {
	const { uid, email } = getState().auth.user
	const toEdit = { ...project, owner: email }
	if (toEdit.id) {
		try {
			const docRef = doc(db, 'users', uid, 'projects', toEdit.id)
			await updateDoc(docRef, toEdit)
			const current = getState().projects.current
			if (current.uid === uid && current.projectId === toEdit.id)
				dispatch(setCurrent({ ...project }))
		} catch (error) {
			alert(error)
		}
	} else {
		try {
			// https://firebase.google.com/docs/firestore/manage-data/add-data#add_a_document
			const collectionRef = collection(db, 'users', uid, 'projects')
			await addDoc(collectionRef, toEdit)
		} catch (error) {
			alert(error)
		}
	}
}

export const handleOpen = (project) => async (dispatch, getState) => {
	const { uid } = getState().auth.user
	try {
		const docRef = doc(db, 'users', uid)
		const data = {
			favoriteProject: {
				projectId: project.id,
				uid: project.hostUid ? project.hostUid : uid
			}
		}
		await updateDoc(docRef, data)
		window.location.reload()
	} catch (error) {
		alert(error)
	}
}

export const handleJoin = (hostUid, projectId) => async (dispatch, getState) => {
	const { uid } = getState().auth.user
	try {
		const projectRef = doc(db, 'users', hostUid, 'projects', projectId)
		const project = (await getDoc(projectRef)).data()
		const { name, owner } = project
		const docRef = doc(db, 'users', uid, 'projects', projectId)
		const joinedProject = { hostUid, name, owner }
		await setDoc(docRef, joinedProject, { merge: true })
		dispatch(handleOpen({ ...joinedProject, id: projectId }))
	} catch (error) {
		alert(error)
	}
}

export const handleLeave = (project) => async (dispatch, getState) => {
	const { uid } = getState().auth.user
	try {
		const projectRef = doc(db, 'users', uid, 'projects', project.id)
		await deleteDoc(projectRef)
	} catch (error) {
		alert(error)
	}
}