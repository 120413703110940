import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

const LinksToolbar = (props) => {
	return (
		<AppBar position="static" color='primary'>
			<Toolbar variant="dense">
				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
					Links
				</Typography>
			</Toolbar>
		</AppBar>)
}

export default LinksToolbar