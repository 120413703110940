import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { listenToAuth } from './actions/auth'
import './index.css'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducer from './reducers'
import middleware from './middleware'
import {
  BrowserRouter
} from "react-router-dom"

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#4a99cd',
      light: '#5ab3e1'
    },
    secondary: {
      main: '#cd7e4a',
    },
  },
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none',
      },
    },
  },
  typography: {
    fontSize: 12,
  },
})

const container = document.getElementById('root')
const root = createRoot(container)

const store = createStore(reducer, middleware)
store.dispatch(listenToAuth())
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
)

