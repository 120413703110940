import React from 'react'
import { useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import StarIcon from '@mui/icons-material/Star'

const Project = ({ project, openProject }) => {
	const current = useSelector(state => state.projects.current)
	return (<ButtonBase onClick={() => openProject(project)}>
		<Paper sx={{ p: '5px 5px', display: 'flex', alignItems: 'center' }} elevation={0}>
			<Typography variant='body1' sx={{ flexGrow: 1 }}> {project.name} {project.hostUid && `(Owned by ${project.owner})`}</Typography>
			{current.id === project.id && <StarIcon color='success'/>}
		</Paper>
	</ButtonBase>)
}

const Projects = ({ openProject }) => {
	const projects = useSelector(state => state.projects.all ? state.projects.all : [])
	return (<Paper sx={{
		flexGrow: 1, overflow: 'auto', display: 'flex', flexDirection: 'column',
		alignItems: 'flex-start'
	}}
		variant='outlined'>
		{projects.map(project => <Project key={project.id} project={project} openProject={openProject} />)}
	</Paper>)
}

export default Projects