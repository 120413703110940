import React from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'


const CheckboxFilter = ({ setFilterAttribute, filter, filterAttribute, attribute, label }) => {
	const dispatch = useDispatch()
	return <Box display='flex' flexDirection='row'>
		<Checkbox checked={filter && filter[filterAttribute]}
			onChange={(e) => dispatch(setFilterAttribute(filterAttribute, e.target.checked))} />
		<FormControl sx={{ my: 0, mx: 2 }}>
			<FormLabel id="filter">{label}</FormLabel>
			<RadioGroup aria-labelledby="filter" name={attribute} row value={filter && filter[attribute] ? 'yes' : 'no'}
				onChange={(e) => dispatch(setFilterAttribute(attribute, e.target.value === 'yes'))}>
				<FormControlLabel value="yes" control={<Radio />} label="yes" />
				<FormControlLabel value="no" control={<Radio />} label="no" />
			</RadioGroup>
		</FormControl>
	</Box>
}

export default CheckboxFilter