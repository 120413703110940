import React from 'react'
import Box from '@mui/material/Box'
import SpecificationList from './specifications/SpecificationList'
import LinkList from './links/LinkList'
import TestCaseList from './testcases/TestCaseList'
import MainToolbar from './MainToolbar'
import useWindowDimensions from '../utils/useWindowDimensions'
import InvitationDialog from './settings/InvitationDialog'
import {  Routes,  Route, Outlet } from "react-router-dom"

// https://stackoverflow.com/questions/57474707/full-height-width-without-scrollbar-in-material-ui-react-app
const Main = () => {
	const { height } = useWindowDimensions()
	const finalHeight = (height - 50) + 'px'
	return (<Box height="100vh" display="flex" flexDirection="column">
		<MainToolbar />
		<Box flex="1" display="flex" flexDirection="row" alignItems="start">
			<Box flex="1" display="flex" flexDirection="column" height={finalHeight}>
				<SpecificationList />
			</Box>
			<Box flex="1" display="flex" flexDirection="column" height={finalHeight}>
				<LinkList />
			</Box>
			<Box flex="1" display="flex" flexDirection="column" height={finalHeight}>
				<TestCaseList/>
			</Box>
		</Box>
		<Outlet/>
	</Box>)
}

const RoutedMain = () => {
	return (<Routes>
		<Route path="" element={<Main/>}>
			<Route path="/invite/:uid/:projectId" element={<InvitationDialog/>}/>
		</Route>
	</Routes>)
}

export default RoutedMain