import {
	doc, updateDoc, arrayUnion, arrayRemove
} from 'firebase/firestore'
import { db } from '../utils/firebase'

export const SHARE_SET = 'SHARE/SET'
export const set = (data) => {
	return {
		type: SHARE_SET,
		data
	}
}

export const handleAddCollaborator = (email) => async (dispatch, getState) => {
	// Do not add if already there
	const { collaborators } = getState().share
	const { current } = getState().projects
	const cleanEmail = email.trim().toLowerCase()
	if (collaborators && cleanEmail in collaborators)
		return
	try {
		const docRef = doc(db, 'users', current.uid, 'projects', current.projectId)
		await updateDoc(docRef, {
			collaborators: arrayUnion(cleanEmail)
		})
	} catch (error) {
		alert(error)
	}
}

export const handleRemoveCollaborator = (email) => async (dispatch, getState) => {
	const { current } = getState().projects
	const cleanEmail = email.trim().toLowerCase()
	try {
		const docRef = doc(db, 'users', current.uid, 'projects', current.projectId)
		await updateDoc(docRef, {
			collaborators: arrayRemove(cleanEmail)
		})
	} catch (error) {
		alert(error)
	}
}