export const DISPLAY_ERRORS = 'ERRORS/DISPLAY'
export const CLEAR_ERRORS = 'ERRORS/CLEAR'
export const CLEAR_ALL = 'ERRORS/CLEAR_ALL'

export const displayAlert = (category, severity, message) => {
	return {
		type: DISPLAY_ERRORS,
		category,
		severity,
		message
	}
}

export const clearAlert = (category) => {
	return {
		type: CLEAR_ERRORS,
		category
	}
}

export const clearAll = () => {
	return {
		type: CLEAR_ALL
	}
}