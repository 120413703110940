import React from 'react'
import { connect } from 'react-redux'
import TestCaseToolbar from './TestCaseToolbar'
import TestCaseItem from './TestCaseItem'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'

const TestCaseList = ({ testcases, currentSpecId, numberOfCases }) => {
	if (!currentSpecId)
		return null
	return (<>
		<TestCaseToolbar />
		<Box overflow="auto" flex="1">
			{!(testcases && testcases.length > 0) && numberOfCases > 0 && <Alert severity="info">There are {numberOfCases} test cases. Start by using the filter</Alert>}
			{!(testcases && testcases.length > 0) && numberOfCases === 0 && <Alert severity="info">This project has no loaded test cases. Please contact your administrator</Alert>}

			<List dense>
				{testcases?.map(o => <TestCaseItem testcase={o} key={o.id} showLink={true} />)}
			</List>
		</Box>
	</>)
}

const mapStateToProps = ({ testcases, specifications, links }, ownProps) => {
	const allSpec = specifications.all
	const currentSpecId = specifications.current
	const filtered = allSpec ? allSpec.filter(o => o.id === currentSpecId) : []
	const currentSpec = filtered.length > 0 ? filtered[0] : null
	const currentLinks = currentSpec && currentSpec.links ? currentSpec.links : []

	const filteredList = Boolean(testcases.filter) && currentSpec ?
		testcases.all.filter(o => testcases.filter.indexOf(o.folder) >= 0 && currentLinks.indexOf(o.id) < 0) : []

	const applyTitleFilter = testcases.applyTitleFilter ? testcases.applyTitleFilter : ''
	const filteredList2 = applyTitleFilter && applyTitleFilter !== '' ?
		filteredList.filter(o => o.title.toLowerCase().indexOf(applyTitleFilter.toLowerCase()) >= 0)
		: filteredList

	const filteredList3 = !testcases.applyLinkedFilter ? filteredList2 :
		testcases.applyLinked ?	filteredList2.filter(o => links.indexOf(o.id) >= 0) :
		filteredList2.filter(o => links.indexOf(o.id) < 0)

	return {
		testcases: filteredList3,
		numberOfCases: testcases && testcases.all ? testcases.all.length : 0,
		currentSpecId,
		...ownProps
	}
}

export default connect(mapStateToProps)(TestCaseList)