import { db } from '../utils/firebase'
import {
	doc, collection, addDoc, deleteDoc, updateDoc,
	getDoc, onSnapshot, orderBy, query
} from 'firebase/firestore'
import { handleRebuildLinks } from './links'

export const SPECIFICATIONS_ADD = 'SPECIFICATIONS/ADD'
export const SPECIFICATIONS_MODIFY = 'SPECIFICATIONS/MODIFY'
export const SPECIFICATIONS_REMOVE = 'SPECIFICATIONS/REMOVE'
export const SPECIFICATIONS_SORT = 'SPECIFICATIONS/SORT'
export const SPECIFICATIONS_SET_CURRENT = 'SPECIFICATIONS/SET_CURRENT'
export const SPECIFICATIONS_UNLOAD = 'SPECIFICATIONS/UNLOAD'
export const SPECIFICATIONS_LOAD = 'SPECIFICATIONS/LOAD'
export const SPECIFICATIONS_SET_FILTER_ATTRIBUTE = 'SPECIFICATIONS/SET_FILTER_ATTRIBUTE'
export const SPECIFICATIONS_SET_FILTER = 'SPECIFICATIONS/SET_FILTER'

export const add = (spec) => {
	return {
		type: SPECIFICATIONS_ADD,
		spec
	}
}

export const modify = (spec) => {
	return {
		type: SPECIFICATIONS_MODIFY,
		spec
	}
}

export const remove = (spec) => {
	return {
		type: SPECIFICATIONS_REMOVE,
		spec
	}
}

export const sort = () => {
	return {
		type: SPECIFICATIONS_SORT
	}
}

export const load = (specs) => {
	return {
		type: SPECIFICATIONS_LOAD,
		specs
	}
}

export const unload = () => {
	return {
		type: SPECIFICATIONS_UNLOAD
	}
}

export const setFilterAttribute = (attribute, value) => {
	return {
		type: SPECIFICATIONS_SET_FILTER_ATTRIBUTE,
		attribute, value
	}
}

export const setFilter = (filter) => {
	return {
		type: SPECIFICATIONS_SET_FILTER,
		filter
	}
}

export const setCurrent = (spec) => {
	return {
		type: SPECIFICATIONS_SET_CURRENT,
		spec
	}
}

export const handleSave = (specification) => async (dispatch, getState) => {
	const { projectId, uid } = getState().projects.current

	if (!specification.id) {
		internalSave(dispatch, uid, projectId, specification)
		return
	}

	const docRef = doc(db, 'users', uid, 'projects', projectId, 'specifications', specification.id)
	const docSnap = await getDoc(docRef)
	if (!docSnap.exists()) {
		await internalSave(dispatch, uid, projectId, specification)
	} else {
		await updateDoc(docRef, specification)
	}
}

const internalSave = async (dispatch, uid, current, specification) => {
	// https://firebase.google.com/docs/firestore/manage-data/add-data#add_a_document
	const collectionRef = collection(db, 'users', uid, 'projects', current, 'specifications')
	await addDoc(collectionRef, specification)
}

export const handleDelete = ({ id }) => async (dispatch, getState) => {
	const { projectId, uid } = getState().projects.current
	const docRef = doc(db, 'users', uid, 'projects', projectId, 'specifications', id)
	await deleteDoc(docRef)
}

export const handleLoad = (uid, projectId, unsubscribers) => (dispatch, getState) => {
	const specificationsRef = collection(db, 'users', uid, 'projects', projectId, 'specifications')
	const q = query(specificationsRef, orderBy('folder'))
	const unsubscribe = onSnapshot(q, (snapshot) => {
		const specs = []
		snapshot.forEach((doc) => specs.push({ ...doc.data(), id: doc.id }))
		dispatch(load(specs))
		dispatch(handleRebuildLinks())
		snapshot.docChanges().forEach((change) => {
			const getData = (change) => {
				const data = change.doc.data()
				return { ...data, id: change.doc.id }
			}
			// https://firebase.google.com/docs/reference/js/firestore_.querysnapshot.md#querysnapshotdocchanges
			//  If this is the first snapshot, all documents will be in the list as 'added' changes.
			if (change.type === "added") {
				dispatch(add(getData(change)))
			}
			if (change.type === "modified") {
				dispatch(modify(getData(change)))
			}
			if (change.type === "removed") {
				dispatch(remove(getData(change)))
			}
			dispatch(handleRebuildLinks())
		})
	})
	unsubscribers.push(unsubscribe)
}