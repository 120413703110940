import React, { useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TreeView from '@mui/lab/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TreeItem from '@mui/lab/TreeItem'
import {
	setFilter, applyTreeFilter, applyTitleFilter,
	setApplyLinkedFilter, setApplyLinked
} from '../../actions/testcases'

import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

const style = {
	container: {
		display: 'flex',
		flexDirection: 'column'
	}
}

const CheckboxFilter = () => {
	const dispatch = useDispatch()
	const { applyLinked, applyLinkedFilter } = useSelector(state => state.testcases)
	return <Box display='flex' flexDirection='row'>
		<Checkbox checked={applyLinkedFilter}
			onChange={(e) => { dispatch(setApplyLinkedFilter(e.target.checked)) }} />
		<FormControl sx={{ my: 0, mx: 2 }}>
			<FormLabel id="filter">Show only linked test cases</FormLabel>
			<RadioGroup aria-labelledby="filter" name='applyLinked' row value={applyLinked ? 'yes' : 'no'}
				onChange={(e) => { dispatch(setApplyLinked(e.target.value === 'yes')) }}>
				<FormControlLabel value="yes" control={<Radio />} label="yes" />
				<FormControlLabel value="no" control={<Radio />} label="no" />
			</RadioGroup>
		</FormControl>
	</Box>
}

const displayChild = (child, tree) => {
	const index = child.lastIndexOf('>')
	const label = index === -1 ? child : child.substring(index + 1)
	return (<TreeItem key={child} nodeId={child} label={label}>
		{
			tree[child] && tree[child].map(o => displayChild(o, tree))
		}
	</TreeItem>)
}

const FilterTreeView = ({ open, handleClose, tree, filter,
	dispatch, expanded }) => {
	const [treeFilter, setTreeFilter] = useState('')
	const [titleFilter, setTitleFilter] = useState('')
	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
			disableEscapeKeyDown maxWidth='md' fullWidth><DialogTitle id="form-dialog-title">Filter the test cases</DialogTitle>
			<DialogContent style={style.container}>
				<CheckboxFilter />
				<TextField label="Filter the title" variant="outlined" sx={{ m: 1 }}
					value={titleFilter}
					onChange={(e) => setTitleFilter(e.target.value)}
					onBlur={() => dispatch(applyTitleFilter(titleFilter))} />
				<TextField label="Filter the tree" variant="outlined" sx={{ m: 1 }}
					value={treeFilter}
					onChange={(e) => setTreeFilter(e.target.value)}
					onBlur={() => dispatch(applyTreeFilter(treeFilter))} />
				<TreeView
					aria-label="multi-select"
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<ChevronRightIcon />}
					multiSelect
					sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}
					expanded={expanded}
					selected={filter}
					onNodeSelect={(e, nodeIds) => dispatch(setFilter(nodeIds))}
				>
					{tree['root'].map(o => displayChild(o, tree))}
				</TreeView>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Close
				</Button>
			</DialogActions>
		</Dialog>)
}

FilterTreeView.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
}

const removeDulplicates = (all) => {
	const foldersWithDuplicates = all ? all.map(o => o.folder) : []
	return [...new Set(foldersWithDuplicates)]
}

const buildTree = (folders) => {
	const tree = { 'root': [] }
	const addParent = (folder) => {
		const index = folder.lastIndexOf('>')
		const parent = index === -1 ? 'root' : folder.substring(0, index)
		if (!tree[parent]) {
			tree[parent] = []
			addParent(parent)
		}
		if (tree[parent].indexOf(folder) === -1)
			tree[parent].push(folder)
	}
	folders.forEach(folder => {
		const index = folder.lastIndexOf('>')
		const parent = index === -1 ? 'root' : folder.substring(0, index)
		if (!tree[parent]) {
			tree[parent] = []
			addParent(parent)
		}
		tree[parent].push(folder)
	})
	return tree
}

const mapStateToProps = ({ testcases, links }, ownProps) => {
	const applyTitleFilter = testcases.applyTitleFilter ? testcases.applyTitleFilter : ''
	const temp = applyTitleFilter && applyTitleFilter !== '' ?
		testcases.all.filter(o => o.title.toLowerCase().indexOf(applyTitleFilter.toLowerCase()) >= 0)
		: testcases.all
	const filteredTestCases = !testcases.applyLinkedFilter ? temp :
		testcases.applyLinked ? temp.filter(o => links.indexOf(o.id) >= 0) :
			temp.filter(o => links.indexOf(o.id) < 0)
	const folders = removeDulplicates(filteredTestCases)
	const applyTreeFilter = testcases.applyTreeFilter ? testcases.applyTreeFilter : ''

	const filteredFolders = testcases.applyTreeFilter && testcases.applyTreeFilter !== '' ?
		folders.filter(o => o.toLowerCase().indexOf(applyTreeFilter.toLowerCase()) >= 0)
		: folders

	const tree = buildTree(filteredFolders)
	const expanded = Object.keys(tree)
	return {
		tree,
		expanded,
		filter: testcases.filter ? testcases.filter : [],
		...ownProps,
	}
}

export default connect(mapStateToProps)(FilterTreeView)