export const compareTestCases = ( a, b ) => {
  if ( a.folder_order < b.folder_order ){
    return -1;
  }
  if ( a.folder_order > b.folder_order ){
    return 1;
  }
  return compareByDisplayOrder(a, b);
}

const compareByDisplayOrder = ( a, b ) => {
  if ( a.display_order < b.display_order ){
    return -1;
  }
  if ( a.display_order > b.display_order ){
    return 1;
  }
  return 0;
}