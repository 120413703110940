import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { handleSignOut } from '../actions/auth'
import { getAuth, sendEmailVerification } from "firebase/auth"


const EmailToVerify = () => {
	const [message, setMessage] = useState('')
	const [severity, setSeverity] = useState()
	const dispatch = useDispatch()
	const logout = () => {
		dispatch(handleSignOut())
	}
	const sendEmail = () => {
		const auth = getAuth()
		setMessage('')
		sendEmailVerification(auth.currentUser).then(() => {
			setMessage('An email was sent')
			setSeverity('success')
		})
			.catch((error) => {
				setMessage(error.toLocaleString())
				setSeverity('error')
			})
	}
	return (
		<Box height="100vh" display="flex" flexDirection="column" alignItems='center' justifyContent='center'>
			<img alt="Fepetra" src={process.env.PUBLIC_URL + '/logo.png'} />
			<Typography variant='body1' sx={{mb: 5}}>Your email has to be verified prior to login</Typography>
			<Button onClick={sendEmail} color='primary' variant='contained'>Resend the email verification</Button>
			<Button onClick={logout} color='secondary' variant='text'>Go back to the login screen</Button>
			{message && <Alert severity={severity}>{message}</Alert>}
		</Box>
	)
}

export default EmailToVerify