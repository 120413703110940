import React from 'react'
import { connect } from 'react-redux'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import IconButton from '@mui/material/IconButton'
import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import EditIcon from '@mui/icons-material/Edit'
import { handleLink, handleUnlink } from '../../actions/links'

const TestCaseItem = ({ testcase, dispatch, showLink, showUnlink }) => {
	return (<><ListItem button >
		<ListItemText primary={testcase.folder} secondary={testcase.title} sx={{ paddingRight: 3 }}/>
		<ListItemSecondaryAction>
			{showLink && <IconButton edge="end"
				size="large"
				aria-label="Link"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				color="inherit" onClick={() => dispatch(handleLink(testcase.id))}>
				<LinkIcon fontSize='small'  />
			</IconButton>}
			{showUnlink && <IconButton edge="end"
				size="large"
				aria-label="Unlink"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				color="inherit" onClick={() => dispatch(handleUnlink(testcase.id))}>
				<LinkOffIcon fontSize='small'  />
			</IconButton>}
			{testcase.url && <a href={testcase.url.replace('/edit/', '/view/')} target="_blank" rel="noreferrer">
							<IconButton edge="end" aria-label="open">
								<OpenInNewIcon fontSize='small'  />
							</IconButton>
						</a>}
			{testcase.url && <a href={testcase.url} target="_blank" rel="noreferrer">
				<IconButton edge="end" aria-label="open">
					<EditIcon fontSize='small'  />
				</IconButton>
			</a>}

		</ListItemSecondaryAction>
	</ListItem>
	</>)
}

const mapStateToProps = (state, ownProps) => {
	return {
		...ownProps
	}
}

export default connect(mapStateToProps)(TestCaseItem)