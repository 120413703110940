import {
	SPECIFICATIONS_ADD, SPECIFICATIONS_MODIFY,
	SPECIFICATIONS_REMOVE, SPECIFICATIONS_SORT,
	SPECIFICATIONS_SET_CURRENT, SPECIFICATIONS_UNLOAD,
	SPECIFICATIONS_SET_FILTER_ATTRIBUTE, SPECIFICATIONS_LOAD,
	SPECIFICATIONS_SET_FILTER
} from '../actions/specifications'

const defaultFilter = {
	filterFolder: false,
	folder: null,
	filterStatus: false,
	status: 'added',
	filterDone: true,
	done: false,
	filterSpecificationChecked: false,
	specificationChecked: false,
	filterTestCreated: false,
	testCreated: false,
	filterTestLinked: false,
	testLinked: false,
	filterTestWritten: false,
	testWritten: false,
	filterFolderChecked: false,
	folderChecked: false,
	filterLater: false,
	later: false,
}

export default function specifications(state = { filter: defaultFilter }, action) {
	switch (action.type) {
		case SPECIFICATIONS_LOAD:
			const newState = { ...state, all: action.specs, allIds: action.specs.map(o => o.id) }
			return filterSpecifications(newState)
		case SPECIFICATIONS_ADD:
			const all = state.all ? state.all : []
			const allIds = state.allIds ? state.allIds : []

			// https://firebase.google.com/docs/reference/js/firestore_.querysnapshot.md#querysnapshotdocchanges
			// If this is the first snapshot, all documents will be in the list as 'added' changes.
			// Therefore, no more adding 
			if (allIds.indexOf(action.spec.id) >= 0)
				return state
			return {
				...state,
				allIds: [...allIds, action.spec.id],
				all: [...all, action.spec]
			}
		case SPECIFICATIONS_MODIFY:
			const allToModify = state.all ? state.all : []
			return {
				...state,
				all: allToModify.map(o => o.id === action.spec.id ? action.spec : o)
			}
		case SPECIFICATIONS_REMOVE:
			const allToDeleteFrom = state.all ? state.all : []
			const allIdsToDeleteFrom = state.allIds ? state.allIds : []
			return {
				...state,
				all: allToDeleteFrom.filter(o => o.id !== action.spec.id),
				allIds: allIdsToDeleteFrom.filter(id => id !== action.spec.id)
			}
		case SPECIFICATIONS_SORT:
			const temp = state.all ? [...state.all] : []
			temp.sort(sortByFolder)
			return {
				...state,
				all: temp
			}
		case SPECIFICATIONS_SET_CURRENT:
			return {
				...state,
				current: action.spec ? action.spec.id : null
			}
		case SPECIFICATIONS_SET_FILTER: {
			const updatedState = {
				...state,
				filter: {...action.filter}
			}
			return filterSpecifications(updatedState)
		}
		case SPECIFICATIONS_SET_FILTER_ATTRIBUTE:
			const { attribute, value } = action
			const currentFilter = state.filter ? state.filter : {}
			const updatedState = {
				...state,
				filter: { ...currentFilter, [attribute]: value }
			}
			return filterSpecifications(updatedState)
		case SPECIFICATIONS_UNLOAD:
			return {
				...state,
				all: []
			}
		default:
			return state
	}
}

const filterSpecifications = (state) => {
	const filter = state.filter ? state.filter : {}
	const toFilter = state.all ? state.all : []
	const filteredSpecifications = toFilter.filter(o => applyFilter(o, filter.filterFolder, 'folder', filter.folder)
		&& applyFilter(o, filter.filterStatus, 'status', filter.status)
		&& applyFilter(o, filter.filterDone, 'done', filter.done)
		&& applyFilter(o, filter.filterSpecificationChecked, 'specificationChecked', filter.specificationChecked)
		&& applyFilter(o, filter.filterTestLinked, 'testLinked', filter.testLinked)
		&& applyFilter(o, filter.filterTestCreated, 'testCreated', filter.testCreated)
		&& applyFilter(o, filter.filterTestWritten, 'testWritten', filter.testWritten)
		&& applyFilter(o, filter.filterFolderChecked, 'folderChecked', filter.folderChecked)
		&& applyFilter(o, filter.filterLater, 'later', filter.later))

	const filteredIds = filteredSpecifications.map(o => o.id)
	filteredSpecifications.sort(sortByFolder)
	const current = state.current
	return {
		...state,
		current: current && filteredIds.indexOf(current) === -1 ? null : current,
		filteredSpecifications,
	}
}

const applyFilter = (object, enabled, field, value) => {
	if (!enabled)
		return true
	const valueToCompare = object[field] ? object[field] : false
	return valueToCompare === value
}

// https://www.javascripttutorial.net/array/javascript-sort-an-array-of-objects/#:~:text=To%20sort%20an%20array%20of%20objects%2C%20you%20use%20the%20sort,determines%20the%20order%20of%20objects.
const sortByFolder = (a, b) => {
	if (a.folder < b.folder) {
		return -1;
	}
	if (a.folder > b.folder) {
		return 1;
	}
	return sortBySpecification(a, b);
}

const sortBySpecification = (a, b) => {
	if (a.specification < b.specification) {
		return -1;
	}
	if (a.specification > b.specification) {
		return 1;
	}
	return 0;
}