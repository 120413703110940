import {
	PROJECTS_SET_CURRENT,
	PROJECTS_ADD, PROJECTS_REMOVE, PROJECTS_MODIFY, PROJECTS_UNLOAD
} from '../actions/projects'
export default function projects(state = { all: [] }, action) {
	switch (action.type) {
		case PROJECTS_SET_CURRENT:
			const { current } = action
			return { ...state, current }
		case PROJECTS_UNLOAD:
			{
				return {
					...state,
					all: []
				}
			}
		case PROJECTS_ADD:
			{
				return {
					...state,
					all: [...state.all, action.project]
				}
			}
		case PROJECTS_REMOVE:
			{
				return {
					...state,
					all: state.all.filter(o => o.id !== action.project.id)
				}
			}
		case PROJECTS_MODIFY:
			{
				return {
					...state,
					all: state.all.map(o => o.id !== action.project.id ? o : { ...o, ...action.project })
				}
			}
		default:
			return state
	}
}