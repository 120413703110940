import { DISPLAY_ERRORS, CLEAR_ERRORS, CLEAR_ALL } from '../actions/errors'

export default function errors(state = {}, action) {
	switch (action.type) {
		case DISPLAY_ERRORS:
			const { message, severity } = action
			return {
				...state,
				[action.category]: { message, severity }
			}
		case CLEAR_ERRORS:
			return {
				...state,
				[action.category]: null
			}
		case CLEAR_ALL:
			return {}
		default:
			return state
	}
}