import React from 'react'
import { useDispatch } from 'react-redux'
import { handleSave } from '../../actions/specifications'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

const style = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: 500
	}
}

const CustomCheckbox = ({ specification, attribute, label, setAttribute }) => {
	return <FormControlLabel control={<Checkbox checked={specification[attribute] ? specification[attribute] : false}
		onChange={(e) => setAttribute(attribute, e.target.checked)} />} label={label} sx={{ my: 0, mx: 1 }} />
}

const SpecificationDialog = (props) => {
	const dispatch = useDispatch()

	const { open, handleClose, specification, setAttribute } = props
	const close = () => {
		handleClose()
	}
	const save = () => {
		dispatch(handleSave(specification))
		handleClose()
	}
	return (
		<Dialog open={open} aria-labelledby="form-dialog-title"
			disableEscapeKeyDown>
			<DialogTitle id="form-dialog-title">Specification Details</DialogTitle>
			<DialogContent style={style.container}>
				<TextField label="Folder" variant="outlined" sx={{ m: 1 }} value={specification.folder} onChange={(e) => setAttribute('folder', e.target.value)} />
				<TextField label="Specification" variant="outlined" multiline
					maxRows={4} minRows={4} sx={{ m: 1 }} value={specification.specification} onChange={(e) => setAttribute('specification', e.target.value)} />
				<FormControl sx={{ my: 0, mx: 2 }}>
					<FormLabel id="status">Status</FormLabel>
					<RadioGroup aria-labelledby="status" name="status" row value={specification.status}
						onChange={(e) => setAttribute('status', e.target.value)}>
						<FormControlLabel value="deleted" control={<Radio />} label="Deleted" />
						<FormControlLabel value="added" control={<Radio />} label="Added" />
					</RadioGroup>
				</FormControl>
				<CustomCheckbox specification={specification} attribute='later'
					label="To do later" setAttribute={setAttribute}/>
				<CustomCheckbox specification={specification} attribute='folderChecked'
					label="The folder is correct" setAttribute={setAttribute}/>
				<CustomCheckbox specification={specification} attribute='specificationChecked'
					label="The specification was checked" setAttribute={setAttribute}/>
				<CustomCheckbox specification={specification} attribute='testCreated'
					label="The test was created" setAttribute={setAttribute}/>
				<CustomCheckbox specification={specification} attribute='testLinked'
					label="The specification is linked to a test" setAttribute={setAttribute}/>
				<CustomCheckbox specification={specification} attribute='testWritten'
					label="The tests are written" setAttribute={setAttribute}/>
				<CustomCheckbox specification={specification} attribute='done'
					label="All tasks related to the specification are done" setAttribute={setAttribute}/>
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color="secondary">
					Cancel
				</Button>
				<Button color="primary" onClick={save} >
					Save
				</Button>
			</DialogActions>
		</Dialog>)
}

SpecificationDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
}

export default SpecificationDialog