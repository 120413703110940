import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterTreeView from './FilterTreeView'

const TestCaseToolbar = ({ folders }) => {
	const [open, setOpen] = useState(false)
	return (
		<AppBar position="static" color='primary'>
			<Toolbar variant="dense">
				<FilterTreeView open={open} handleClose={() => setOpen(false)}/>
				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
					Tests
				</Typography>
				<IconButton
					size="large"
					aria-label="Sync Test Cases"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					color="inherit" onClick={() => setOpen(true)}>
					<FilterAltIcon />
				</IconButton>
			</Toolbar>
		</AppBar>)
}

export default TestCaseToolbar