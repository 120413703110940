import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

// Your API keys, database URL, app id etc are meant to be public.
// https://stackoverflow.com/questions/61600373/what-is-the-best-way-to-secure-firebase-api-keys-in-a-react-app-so-it-is-not-acc
const firebaseConfig = {
  apiKey: "AIzaSyBLIBey4o4krfMBcibcH-0KgJNBudUXvFE",
  authDomain: "fepetra-a6ec8.firebaseapp.com",
  projectId: "fepetra-a6ec8",
  storageBucket: "fepetra-a6ec8.appspot.com",
  messagingSenderId: "455745303497",
  appId: "1:455745303497:web:07904e7523e1a39a66ae18",
  measurementId: "G-XYV4JNMMNH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export default app