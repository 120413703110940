import { db } from '../utils/firebase'
import { collection, onSnapshot  } from 'firebase/firestore'
import { compareTestCases } from '../utils/comparer'

export const TESTCASES_ADD = 'TESTCASES/ADD'
export const TESTCASES_UNLOAD = 'TESTCASES/UNLOAD'
export const TESTCASES_SET_FILTER = 'TESTCASES/SET_FILTER'
export const TESTCASES_APPLY_TREE_FILTER = 'TESTCASES/APPLY_TREE_FILTER'
export const TESTCASES_APPLY_TITLE_FILTER = 'TESTCASES/APPLY_TITLE_FILTER'
export const TESTCASES_APPLY_LINKED_FILTER = 'TESTCASES/APPLY_LINKED_FILTER'
export const TESTCASES_APPLY_LINKED = 'TESTCASES/APPLY_LINKED'

export const add = (testcases) => {
	return {
		type: TESTCASES_ADD,
		testcases
	}
}

export const unload = () => {
	return {
		type: TESTCASES_UNLOAD
	}
}

export const setFilter = (filter) => {
	return {
		type: TESTCASES_SET_FILTER,
		filter
	}
}

export const setApplyLinkedFilter = (applyLinkedFilter) => {
	return {
		type: TESTCASES_APPLY_LINKED_FILTER,
		applyLinkedFilter
	}
}

export const setApplyLinked = (applyLinked) => {
	return {
		type: TESTCASES_APPLY_LINKED,
		applyLinked
	}
}

export const applyTreeFilter = (filter) => {
	return {
		type: TESTCASES_APPLY_TREE_FILTER,
		filter
	}
}

export const applyTitleFilter = (filter) => {
	return {
		type: TESTCASES_APPLY_TITLE_FILTER,
		filter
	}
}


export const handleLoad = (uid, projectId, unsubscribers) => (dispatch, getState) => {
	const testcasesRef = collection(db, 'users', uid, 'projects', projectId, 'testcases')
	const unsubscribeTestcase = onSnapshot(testcasesRef, (snapshot) => {
		snapshot.docChanges().forEach((change) => {
			const getData = (change) => {
				const { cases } = change.doc.data()
				cases.sort(compareTestCases)
				dispatch(add(cases))
			}
			// https://firebase.google.com/docs/reference/js/firestore_.querysnapshot.md#querysnapshotdocchanges
			//  If this is the first snapshot, all documents will be in the list as 'added' changes.
			if (change.type === "added") {
				getData(change)
			}
			if (change.type === "modified") {
				dispatch(unload())
				getData(change)
			}
			if (change.type === "removed") {
				dispatch(unload())
			}
		})
	})
	unsubscribers.push(unsubscribeTestcase)
}