import { applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

const middlewares = [thunk]

// https://www.npmjs.com/package/redux-logger#options
const allPredicate = (getState, action) => false//action.type.startsWith('LINKS')
if(process.env.NODE_ENV === `development`)
	middlewares.push(createLogger({
		predicate: allPredicate,
		collapsed: true
	}))

export default applyMiddleware(...middlewares)