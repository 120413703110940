import { db } from '../utils/firebase'
import { doc, updateDoc, getDoc } from 'firebase/firestore'

export const LINKS_SET = 'LINKS/SET'

const set = (links) => {
	return {
		type: LINKS_SET,
		links
	}
}

export const handleRebuildLinks = () => (dispatch, getState) => {
	const specifications = getState().specifications.all
	const links = []
	specifications.forEach(o => {
		if (!o.links)
			return
		o.links.forEach(l => {
			if(links.indexOf(l) >= 0)
				return
			links.push(l)
		})
	})
	dispatch(set(links))
}

export const handleLink = (testcaseId) => async (dispatch, getState) => {
	const { projectId, uid } = getState().projects.current
	const currentSpecId = getState().specifications.current
	if (!currentSpecId)
		return

	const docRef = doc(db, 'users', uid, 'projects', projectId, 'specifications', currentSpecId)
	const docSnap = await getDoc(docRef)
	const data = docSnap.data()
	const currentArray = data.links ? data.links : []
	if (currentArray.indexOf(testcaseId) < 0) {
		await updateDoc(docRef, { links: [...currentArray, testcaseId] })
	}
}

export const handleUnlink = (testcaseId) => async (dispatch, getState) => {
	const { projectId, uid } = getState().projects.current
	const currentSpecId = getState().specifications.current
	if (!currentSpecId)
		return

	const docRef = doc(db, 'users', uid, 'projects', projectId, 'specifications', currentSpecId)
	const docSnap = await getDoc(docRef)
	const data = docSnap.data()
	const currentArray = data.links ? data.links : []
	await updateDoc(docRef, { links: currentArray.filter(o => o !== testcaseId) })
}