import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { db } from '../../utils/firebase'
import { doc, getDoc } from 'firebase/firestore'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { handleJoin } from '../../actions/projects'

const style = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: 500
	}
}

const InvitationDialog = (props) => {
	const dispatch = useDispatch()
	const { uid, projectId } = useParams()
	const currentUserId = useSelector(state => state.auth?.user?.uid)
	const [projectName, setProjectName] = useState('')
	const [error, setError] = useState('')
	const navigate = useNavigate()
	useEffect(() => {
		const docRef = doc(db, 'users', uid, 'projects', projectId)
		getDoc(docRef).then(projectDoc => {
			setProjectName(projectDoc.data().name)
		}).catch(error => setError(error.toLocaleString()))
	}, [uid, projectId])
	const close = () => {
		navigate('/')
	}
	const join = () => {
		dispatch(handleJoin(uid, projectId))
		navigate('/')
	}
	return (
		<Dialog open aria-labelledby="form-dialog-title"
			disableEscapeKeyDown>
			<DialogTitle id="form-dialog-title">Join the project</DialogTitle>
			{error && <>
				<DialogContent style={style.container}>
					<Typography variant='body1'>{error}</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={close} color="secondary">
						Close
					</Button>
				</DialogActions></>}
			{!error && currentUserId === uid && uid && <>
				<DialogContent style={style.container}>
					<Typography variant='body1'>You already have access to this project {projectName}</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={close} color="secondary">
						Close
					</Button>
				</DialogActions>
			</>}
			{
				!error && currentUserId && uid && currentUserId !== uid && <>
					<DialogContent style={style.container}>
						<Typography variant='body1'>You have been invited to join this project {projectName}.
							Would you like to join it?</Typography>
					</DialogContent>
					<DialogActions>
						<Button onClick={close} color="secondary">
							No
						</Button>
						<Button onClick={join} color="primary">
							Yes
						</Button>
					</DialogActions>
				</>
			}

		</Dialog>)
}

export default InvitationDialog