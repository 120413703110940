import { loadProject, unloadProject, addProject, removeProject, modifyProject } from './projects'
import {
	doc, getDoc, setDoc, collection,
	addDoc, updateDoc, onSnapshot
} from 'firebase/firestore'
import { handleSignOut } from './auth'
import { set } from './share'
import { db } from '../utils/firebase'

const DEFAULT_PROJECT = 'default'

const unsubscribers = []
export const unload = () => async (dispatch, getState) => {
	unsubscribers.forEach(o => o())
	dispatch(unloadProject())
}

export const load = (uid) => async (dispatch, getState) => {
	const docRef = doc(db, 'users', uid)
	let docExists = false

	// if still the user managed to create their credential in firebase
	// if they don't have access to the docs then log them out
	try {
		const docSnap = await getDoc(docRef)
		docExists = docSnap.exists()
	} catch (error) {
		dispatch(handleSignOut())
	}

	if (!docExists) {
		// https://firebase.google.com/docs/firestore/manage-data/transactions
		// about getting a new Id
		// https://stackoverflow.com/questions/63462590/how-to-get-the-id-of-a-newly-created-document-during-a-transaction-in-firestore
		// TODO: 	Add these under a transaction 
		const userRef = doc(db, 'users', uid)
		await setDoc(userRef, {})
		const docRef = await addDoc(collection(db, 'users', uid, 'projects'), { name: DEFAULT_PROJECT, isDefault: true })
		await updateDoc(userRef, {
			favoriteProject: {
				projectId: docRef.id,
				uid: uid
			}
		})
	}

	const userDoc = await getDoc(docRef)
	const user = userDoc.data()

	dispatch(loadProject(user.favoriteProject, unsubscribers))
	loadProjects(uid, dispatch)
	loadShare(uid, user.favoriteProject, dispatch)
}


const loadProjects = (uid, dispatch) => {
	const projectsRef = collection(db, 'users', uid, 'projects')

	const unsubscribe = onSnapshot(projectsRef, (snapshot) => {
		snapshot.docChanges().forEach((change) => {
			const getData = (change) => {
				const data = change.doc.data()
				return { ...data, id: change.doc.id }
			}
			if (change.type === "added")
				dispatch(addProject(getData(change)))
			if (change.type === "modified")
				dispatch(modifyProject(getData(change)))
			if (change.type === "removed")
				dispatch(removeProject(getData(change)))
		})
	})
	unsubscribers.push(unsubscribe)
}

const loadShare = (uid, project, dispatch) => {
	const docRef = doc(db, 'users', project.uid, 'projects', project.projectId)
	const unsubscribe = onSnapshot(docRef, (snapshot) => {
		const data = snapshot.data()
		dispatch(set(data))
	})
	unsubscribers.push(unsubscribe)
}