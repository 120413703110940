import { combineReducers } from 'redux'

import auth from './auth'
import specifications from './specifications'
import testcases from './testcases'
import projects from './projects'
import share from './share'
import links from './links'
import errors from './errors'

export default combineReducers({ auth, specifications, testcases, projects, share, errors, links })