import {
	TESTCASES_ADD, TESTCASES_UNLOAD, TESTCASES_SET_FILTER,
	TESTCASES_APPLY_TREE_FILTER, TESTCASES_APPLY_TITLE_FILTER,
	TESTCASES_APPLY_LINKED_FILTER, TESTCASES_APPLY_LINKED
} from '../actions/testcases'

export default function testcases(state = { applyLinkedFilter: false, applyLinked: false }, action) {
	switch (action.type) {
		case TESTCASES_ADD:
			const all = state.all ? state.all : []
			return {
				...state,
				all: [...all, ...action.testcases]
			}
		case TESTCASES_UNLOAD:
			return {
				...state,
				all: []
			}
		case TESTCASES_SET_FILTER:
			return {
				...state,
				filter: action.filter
			}
		case TESTCASES_APPLY_TREE_FILTER:
			return {
				...state,
				applyTreeFilter: action.filter
			}
		case TESTCASES_APPLY_TITLE_FILTER:
			return {
				...state,
				applyTitleFilter: action.filter
			}
		case TESTCASES_APPLY_LINKED_FILTER:
			return {
				...state,
				applyLinkedFilter: action.applyLinkedFilter
			}
		case TESTCASES_APPLY_LINKED:
			return {
				...state,
				applyLinked: action.applyLinked
			}
		default:
			return state
	}
}