import React from 'react'
import { connect } from 'react-redux'
import LinkToolbar from './LinkToolbar'
import TestCaseItem from '../testcases/TestCaseItem'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import { compareTestCases } from '../../utils/comparer'

const LinkList = ({ testcases, currentSpecId }) => {
	if(!currentSpecId)
		return null
	return (<>
		<LinkToolbar />
		<Box overflow="auto" flex="1">
			<List dense>
				{testcases?.map(o => <TestCaseItem testcase={o} key={o.id} showUnlink={true} />)}
			</List>
		</Box>
	</>)
}

const mapStateToProps = ({ testcases, specifications }, ownProps) => {
	const allSpec = specifications.all
	const currentSpecId = specifications.current
	const filtered = allSpec ? allSpec.filter(o => o.id === currentSpecId) : []
	const currentSpec = filtered.length > 0 ? filtered[0] : null
	const filteredList = testcases.all && currentSpec && currentSpec.links ?
		testcases.all.filter(o => currentSpec.links.indexOf(o.id) >= 0) : []
	filteredList.sort(compareTestCases)
	return {
		testcases: filteredList,
		currentSpecId,
		...ownProps
	}
}

export default connect(mapStateToProps)(LinkList)