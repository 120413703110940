import {
	SHARE_SET
} from '../actions/share'
export default function share(state = {}, action) {
	switch (action.type) {
		case SHARE_SET: {
			return { ...state, ...action.data }
		}
		default:
			return state
	}
}