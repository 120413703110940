import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import MenuItem from '@mui/material/MenuItem'
import Snackbar from '@mui/material/Snackbar'
import {
	getAuth, sendPasswordResetEmail
} from "firebase/auth"

const ResetPassword = () => {
	const user = useSelector(state => state.auth.user)
	const [open, setOpen] = useState(false)
	const [message, setMessage] = useState('')

	const handleResetPassword = () => {
		const auth = getAuth()
		const email = user.email
		sendPasswordResetEmail(auth, email)
			.then(() => {
				setOpen(true)
				setMessage('A email has been sent to you to allow you to change your password')
			}).catch((error) => {
				const errorMessage = `${error.code}: ${error.message}`
				setOpen(true)
				setMessage(errorMessage)
			})
	}
	return (<>
		<MenuItem onClick={handleResetPassword}>Reset your password</MenuItem>
		<Snackbar
			open={open}
			autoHideDuration={3000}
			onClose={() => setOpen(false)}
			message={message}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
		/>
	</>)
}

export default ResetPassword