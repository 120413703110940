import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import { handleRemoveCollaborator } from '../../actions/share'

const Collaborator = ({ email }) => {
	const dispatch = useDispatch()
	return (<Paper sx={{p: '5px 5px', display: 'flex', alignItems: 'center'}} elevation={0}>
		<Typography variant='body1' sx={{flexGrow: 1}}> {email}</Typography>
		<IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"
			onClick={() => dispatch(handleRemoveCollaborator(email))}>
			<DeleteIcon />
		</IconButton>
	</Paper>)
}

const Collaborators = () => {
	const collaborators = useSelector(state => state.share.collaborators ? state.share.collaborators : [])
	collaborators.sort()
	return (<Paper sx={{ height: 300, overflow: 'auto', mt: 2 }} variant='outlined'>
		{collaborators.map(email => <Collaborator key={email} email={email} />)}
	</Paper>)
}

export default Collaborators