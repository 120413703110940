import React from 'react'
// Import FirebaseAuth and firebase.
// eslint-disable-next-line
import app from '../utils/firebase'
import { getAuth } from 'firebase/auth'
import Box from '@mui/material/Box'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

// Configure FirebaseUI.
const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // https://stackoverflow.com/questions/68490981/how-do-i-set-firebase-auth-ui-disablesignup-correctly
      disableSignUp: {
        status: true
      }
    }
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  }
}

const SignInScreen = () => {
  return (
    <Box height="100vh" display="flex" flexDirection="column" alignItems='center' justifyContent='center'>
        <img alt="Fepetra" src={process.env.PUBLIC_URL + '/logo.png'} />
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
    </Box>
  )
}

export default SignInScreen