import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import AddCollaborator from './AddCollaborator'
import Collaborators from './Collaborators'
import Snackbar from '@mui/material/Snackbar'

const style = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: 500
	}
}

const ShareDialog = (props) => {
	const current = useSelector(state => state.projects.current)
	const { open, handleClose } = props
	const [openSnackbar, setOpenSnackbar] = useState(false)

	const close = () => {
		handleClose()
	}
	const copyLink = () => {
		const location = window.location
		const baseURL = `${location.protocol}//${location.host}`
		navigator.clipboard.writeText(`${baseURL}/invite/${current.uid}/${current.projectId}`)
		setOpenSnackbar(true)
	}
	return (
		<Dialog open={open} aria-labelledby="form-dialog-title"
			disableEscapeKeyDown>
			<DialogTitle id="form-dialog-title">Share this project</DialogTitle>
			<DialogContent style={style.container}>
				<AddCollaborator />
				<Collaborators />
			</DialogContent>
			<DialogActions>
				<Button onClick={close} color="secondary">
					Close
				</Button>
				<Button onClick={copyLink} color="primary">
					Copy the link
				</Button>
			</DialogActions>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={3000}
				onClose={() => setOpenSnackbar(false)}
				message="URL copied into the clipboard"
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			/>
		</Dialog>)
}

ShareDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
}

export default ShareDialog