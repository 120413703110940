import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import LogoutIcon from '@mui/icons-material/Logout'
import { handleSignOut } from '../actions/auth'
import SettingsMenu from './settings/SettingsMenu'

const MainToolbar = () => {
	const dispatch = useDispatch()
	const user = useSelector(state => state.auth.user)
	const current = useSelector(state => state.projects.current)
	const owner = current && current.owner && current.owner !== user.email ? ` (owned by ${current.owner})` : ''
	const name = current ? `${current.name}${owner}` : ''
	return (<AppBar position="static" color='primary'>
		<Toolbar variant="dense">
			<Avatar alt="Fepetra" src={process.env.PUBLIC_URL + '/logo.png'} sx={{ marginRight: 1 }} />
			<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
				Fepetra
			</Typography>
			<Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
				{name}
			</Typography>
			<Typography variant="subtitle1" component="div">
				{user.email}
			</Typography>
			<SettingsMenu />
			<IconButton
				size="large"
				aria-label="Logout"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				color="inherit"
				onClick={() => dispatch(handleSignOut())}>
				<LogoutIcon />
			</IconButton>
		</Toolbar>
	</AppBar>)
}

export default MainToolbar