import React from 'react'
import { connect } from 'react-redux'
import SpecificationToolbar from './SpecificationToolbar'
import SpecificationItem from './SpecificationItem'
import List from '@mui/material/List'
import Box from '@mui/material/Box'

const SpecificationList = ({ specifications }) => {
	return (<>
		<SpecificationToolbar />
		<Box overflow="auto" flex="1">
			<List dense>
				{specifications?.map(o => <SpecificationItem specification={o} key={o.id} />)}
			</List>
		</Box>
	</>)
}

const mapStateToProps = ({ specifications }, ownProps) => {
	return {
		specifications: specifications.filteredSpecifications ? specifications.filteredSpecifications : [],
		...ownProps
	}
}

export default connect(mapStateToProps)(SpecificationList)