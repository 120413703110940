import parse from 'html-react-parser'

//https://www.npmjs.com/package/html-react-parser
//https://stackoverflow.com/questions/39758136/render-html-string-as-real-html-in-a-react-component
export const parseSpecification = (text) => {
	const countPlusPlus = (text.match(/\+\+/g) || []).length
	const countMinusMinus = (text.match(/--/g) || []).length
	let htmlText = countPlusPlus % 2 !== 0 ? text + ' <span style="color: red">Unbalanced ++</span>' : text
	htmlText = countMinusMinus % 2 !== 0 ? text + ' <span style="color: red">Unbalanced --</span>' : htmlText
	htmlText = htmlText.replace(/\n/g, '<br/>')
	htmlText = change(htmlText, '++', '<span style="color: green">', '</span>')
	htmlText = change(htmlText, '--', '<span style="color: red"><strike>', '</strike></span>')

	return parse(htmlText)
}

const change = (text, char, beginTag, endTag) => {
	const splitted = text.split(char)
	for (let i = 1; i < splitted.length; i++) {
		if (i % 2 === 1)
			splitted[i] = beginTag + splitted[i] + endTag 
	}

	return splitted.join('')
}