import {
	LINKS_SET
} from '../actions/links'

export default function links(state = [], action) {
	switch (action.type) {
		case LINKS_SET:{
			return action.links
		}	
		default:
			return state
	}
}