import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Projects from './Projects'
import IconButton from '@mui/material/IconButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { handleSave, handleOpen, handleLeave } from '../../actions/projects'

const style = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: 500,
		height: 400
	}
}

const SingleProjectContent = ({ project, setProject, close }) => {
	return project.hostUid ? <JoinedProjectContent project={project}
		setProject={setProject} close={close} /> : <OwnedProjectContent project={project}
			setProject={setProject} close={close} />
}

const JoinedProjectContent = ({ project, setProject, close }) => {
	const dispatch = useDispatch()
	const current = useSelector(state => state.projects.current)
	const leave = () => {
		if (current.id === project.id) {
			alert("Please open another project before leaving this one")
			return
		}
		dispatch(handleLeave(project))
		close()
	}
	const open = () => {
		dispatch(handleOpen(project))
	}
	return <>
		<DialogTitle id="form-dialog-title">Project detail</DialogTitle>
		<DialogContent style={style.container}>
			<Typography variant='h5'>Name: {project.name}</Typography>
			<Typography variant='body1'>This is a project that you have joined. It is managed by {project.owner}</Typography>
		</DialogContent>
		<DialogActions>
			<Button onClick={close} color="secondary">
				Cancel
			</Button>
			<Button onClick={leave} color="primary" variant="text">Leave</Button>
			<Button onClick={open} color="primary" variant="contained">Open</Button>
		</DialogActions>
	</>
}

const OwnedProjectContent = ({ project, setProject, close }) => {
	const dispatch = useDispatch()
	const save = () => {
		dispatch(handleSave(project))
		close()
	}
	const open = () => {
		dispatch(handleOpen(project))
	}
	const deleteProject = () => {
		alert("This feature is coming soon!")
	}
	return <>
		<DialogTitle id="form-dialog-title">Project detail</DialogTitle>
		<DialogContent style={style.container}>
			<TextField label="Name" variant="outlined" sx={{ m: 1 }} value={project.name}
				onChange={(e) => setProject({ ...project, name: e.target.value })} />
		</DialogContent>
		<DialogActions>
			<Button onClick={close} color="secondary">
				Cancel
			</Button>
			{project.id && !project.isDefault && <Button onClick={deleteProject} color="secondary" variant="outlined">Delete</Button>}
			<Button onClick={save} color="primary" variant="outlined"
				disabled={!(project.name && project.name.trim().length >= 3)}>Save</Button>
			{project.id && <Button onClick={open} color="primary" variant="contained">Open</Button>}
		</DialogActions>
	</>
}

const ProjectsDialogContent = ({ close, openProject }) => {
	return (<>
		<DialogTitle id="form-dialog-title">Manage the projects
			<IconButton
				size="small"
				aria-label="Add project"
				aria-controls="menu-appbar"
				aria-haspopup="true"
				color="inherit" onClick={() => openProject({ name: '' })}>
				<AddCircleIcon />
			</IconButton>
		</DialogTitle>
		<DialogContent style={style.container}>
			<Projects openProject={openProject} />
		</DialogContent>
		<DialogActions>
			<Button onClick={close} color="secondary">
				Close
			</Button>
		</DialogActions>
	</>)
}

const ProjectsDialog = (props) => {
	const { open, handleClose } = props
	const [project, setProject] = useState(null)

	const close = () => {
		handleClose()
	}
	const openProject = (project) => {
		setProject({ ...project })
	}

	return (
		<Dialog open={open} aria-labelledby="form-dialog-title"
			disableEscapeKeyDown>
			{
				!project ? <ProjectsDialogContent close={close} openProject={openProject} />
					: <SingleProjectContent project={project} setProject={setProject} close={() => setProject(null)} />
			}
		</Dialog>)
}

ProjectsDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
}

export default ProjectsDialog