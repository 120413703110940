import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { setFilterAttribute } from '../../actions/specifications'
import CheckboxFilter from '../common/CheckboxFilter'

const style = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: 500
	}
}

const FilterDialog = ({ open, handleClose, folders, filter, dispatch }) => {
	return (
		<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title"
			disableEscapeKeyDown><DialogTitle id="form-dialog-title">Filter the specifications</DialogTitle>
			<DialogContent style={style.container}>
				<Box display='flex' flex='1' flexDirection='row'>
					<Checkbox checked={filter.filterFolder}
						onChange={(e) => dispatch(setFilterAttribute('filterFolder', e.target.checked))} />
					<Autocomplete sx={{ m: 1, flexGrow: 1 }}
						value={filter?.folder}
						onChange={(event, newValue) => {
							dispatch(setFilterAttribute('folder', newValue))
						}}
						options={folders}
						renderInput={(params) => <TextField {...params} label="Folder"
						/>}
					/>
				</Box>
				<Box display='flex' flex='1' flexDirection='row'>
					<Checkbox checked={filter.filterStatus}
						onChange={(e) => dispatch(setFilterAttribute('filterStatus', e.target.checked))} />
					<FormControl sx={{ my: 0, mx: 2 }}>
						<FormLabel id="status">Status</FormLabel>
						<RadioGroup aria-labelledby="status" name="status" row value={filter?.status}
							onChange={(e) => dispatch(setFilterAttribute('status', e.target.value))}>
							<FormControlLabel value="deleted" control={<Radio />} label="Deleted" />
							<FormControlLabel value="added" control={<Radio />} label="Added" />
						</RadioGroup>
					</FormControl>
				</Box>
				<CheckboxFilter setFilterAttribute={setFilterAttribute}
					filter={filter} filterAttribute='filterLater'
					attribute='later' label='To do later' />
				<CheckboxFilter setFilterAttribute={setFilterAttribute}
					filter={filter} filterAttribute='filterFolderChecked'
					attribute='folderChecked' label='The folder is correct' />
				<CheckboxFilter setFilterAttribute={setFilterAttribute}
					filter={filter} filterAttribute='filterSpecificationChecked'
					attribute='specificationChecked' label='The specification was checked' />
				<CheckboxFilter setFilterAttribute={setFilterAttribute}
					filter={filter} filterAttribute='filterTestCreated'
					attribute='testCreated' label='The test was created' />
				<CheckboxFilter setFilterAttribute={setFilterAttribute}
					filter={filter} filterAttribute='filterTestLinked'
					attribute='testLinked' label='The specification is linked to a test' />
				<CheckboxFilter setFilterAttribute={setFilterAttribute}
					filter={filter} filterAttribute='filterTestWritten'
					attribute='testWritten' label='The tests are written' />
				<CheckboxFilter setFilterAttribute={setFilterAttribute}
					filter={filter} filterAttribute='filterDone'
					attribute='done' label='All tasks related to the specification are done' />

			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Close
				</Button>
			</DialogActions>
		</Dialog>)
}

FilterDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
}

const applyFilter = (object, enabled, field, value) => {
	if (!enabled)
		return true
	const valueToCompare = object[field] ? object[field] : false
	return valueToCompare === value
}

const mapStateToProps = ({ specifications }, ownProps) => {
	const filter = specifications.filter ? specifications.filter : {}
	const foldersWithDuplicates = specifications.all ? specifications.all.filter(o => applyFilter(o, filter.filterStatus, 'status', filter.status))
		.filter(o => applyFilter(o, filter.filterStatus, 'status', filter.status)
			&& applyFilter(o, filter.filterDone, 'done', filter.done)
			&& applyFilter(o, filter.filterSpecificationChecked, 'specificationChecked', filter.specificationChecked)
			&& applyFilter(o, filter.filterTestLinked, 'testLinked', filter.testLinked)
			&& applyFilter(o, filter.filterTestWritten, 'testWritten', filter.testWritten)
			&& applyFilter(o, filter.filterFolderChecked, 'folderChecked', filter.folderChecked)
			&& applyFilter(o, filter.filterLater, 'later', filter.later)).map(o => o.folder) : []
	const folders = [...new Set(foldersWithDuplicates)]
	return {
		folders,
		filter,
		...ownProps,
	}
}

export default connect(mapStateToProps)(FilterDialog)