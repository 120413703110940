import { getAuth } from 'firebase/auth'
import { unload as unloadSubscribers, load } from './loader'

export const AUTH_SIGN_IN = 'AUTH/SIGN_IN'
export const AUTH_SIGN_OUT = 'AUTH/SIGN_OUT'

const signIn = ({ email, uid, emailVerified }) => {
	return {
		type: AUTH_SIGN_IN,
		user: { email, uid, emailVerified }
	}
}
const signOut = () => {
	return {
		type: AUTH_SIGN_IN
	}
}

const signOutSequence = (dispatch) => {
	dispatch(signOut())
	dispatch(unloadSubscribers())
}

export const handleSignOut = () => (dispatch, getState) => {
	getAuth().signOut()
	signOutSequence(dispatch)
}

export const listenToAuth = () => (dispatch, getState) => {
	const auth = getAuth()
	auth.onAuthStateChanged(user => {
		if (!!user) {
			dispatch(signIn(user))
			if (user.emailVerified)
				dispatch(load(user.uid))
		}
		else
			signOutSequence(dispatch)
	})
}