import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ShareIcon from '@mui/icons-material/Share'
import { handleAddCollaborator } from '../../actions/share'

const AddCollaborator = () => {
	const [email, setEmail] = useState('')
	const dispatch = useDispatch()
	const add = () => {
		if (!validate(email)) {
			alert('Please provide a valid email')
			return
		}
		dispatch(handleAddCollaborator(email))
		setEmail('')
	}

	const validate = (email) => {
		const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/;
		return reg.test(email)
	}
	
	return (
		<Paper
			sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
			variant='outlined'>
			<InputBase
				sx={{ ml: 1, flex: 1 }}
				placeholder="collaborator@email.com"
				inputProps={{ 'aria-label': 'Indicate the mail of the collaborator to add' }}
				value={email}
				onChange={(event) => setEmail(event.target.value)}
			/>
			<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
			<IconButton color="primary" sx={{ p: '10px' }} aria-label="directions"
				onClick={add}>
				<ShareIcon />
			</IconButton>
		</Paper>
	)
}

export default AddCollaborator