import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import SpecificationDialog from './SpecificationDialog'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import FilterDialog from './FilterDialog'
import DashboardDialog from './DashboardDialog'
import { sort } from '../../actions/specifications'

const template = {
	status: 'added', done: false,
	folder: '', specification: ''
}
const isAnyFilterTicked = (filter) => {
	let ret = false
	for(let attr in filter){
		if(!attr.startsWith('filter'))
			continue
		ret = ret || filter[attr]
	}
	return ret
}
const SpecificationToolbar = ({ filter, numberOfDisplayedSpecs }) => {
	const dispatch = useDispatch()
	const [open, setOpen] = useState(false)
	const [openFilter, setOpenFilter] = useState(false)
	const [specification, setSpecification] = useState(template)
	const [openDashboard, setOpenDashboard] = useState(false)
	const setAttribute = (field, value) => {
		setSpecification({ ...specification, [field]: value })
	}
	const openDialog = () => {
		setOpen(true)
		setSpecification({ ...template })
	}
	const handleSort = () => {
		dispatch(sort())
	}
	return (
		<AppBar position="static" color='primary'>
			<SpecificationDialog open={open} handleClose={() => setOpen(false)}
				specification={specification}
				setAttribute={setAttribute} />
			<FilterDialog open={openFilter} handleClose={() => setOpenFilter(false)} />
			<DashboardDialog open={openDashboard} handleClose={() => setOpenDashboard(false)} />
			<Toolbar variant="dense">
				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
					Specifications
				</Typography>
				<Typography variant="body1" component="div">

				</Typography>
				{isAnyFilterTicked(filter)
					&& <Typography variant="body1" component="div" >
						Filtered - Showing {numberOfDisplayedSpecs}
					</Typography>}
				<IconButton
					size="small"
					aria-label="Sync Test Cases"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					color="inherit" onClick={() => setOpenFilter(true)}>
					<FilterAltIcon />
				</IconButton>
				<IconButton
					size="small"
					aria-label="Sort"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					color="inherit" onClick={handleSort}>
					<SortByAlphaIcon />
				</IconButton>
				<IconButton
					size="small"
					aria-label="Add specification"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					color="inherit" onClick={openDialog}>
					<AddCircleIcon />
				</IconButton>
				<IconButton
					size="small"
					aria-label="Dashboard"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					color="inherit" onClick={()=>setOpenDashboard(true)}>
					<StackedBarChartIcon />
				</IconButton>
			</Toolbar>
		</AppBar>)
}

const mapStateToProps = ({ specifications }, ownProps) => {
	return {
		filter: specifications.filter ? specifications.filter : {},
		numberOfDisplayedSpecs: (specifications.filteredSpecifications ? specifications.filteredSpecifications : []).length,
		...ownProps
	}
}

export default connect(mapStateToProps)(SpecificationToolbar)