import { AUTH_SIGN_IN, AUTH_SIGN_OUT } from '../actions/auth'
export default function auth(state = {}, action) {
	switch(action.type){
		case AUTH_SIGN_IN:
			const { user } = action
			return { ...state, user}
		case AUTH_SIGN_OUT:
			return { ...state, user: null}
		default:
			return state
	}
}